<template>
  <div v-if="entity">
    test
  </div>
</template>

<script>
import StoreMixin from './storeMixin'

export default {
  name: 'EstImageMessageNew',
  components: {
  },
  mixins: [StoreMixin],
  data() {
    return { }
  },
  computed: {},
  watch: {  },
  created() { },
  mounted() {},
  methods: { }
}
</script>
